// src/pages/Checkout.js

import React, { useEffect, useState, useContext } from 'react';
import { useStripe, useElements, CardElement } from '@stripe/react-stripe-js';
import { CartContext } from '../components/context/CartContext';
import { useNavigate } from 'react-router-dom';
import CartItem from '../components/CartItem';
import '../styles/Checkout.css';

const Checkout = () => {
  const stripe = useStripe();
  const elements = useElements();
  const navigate = useNavigate();
  const { cartItems, clearCart } = useContext(CartContext);
  const [clientSecret, setClientSecret] = useState('');
  const [isProcessing, setIsProcessing] = useState(false);

  // State variables for prices
  const [subtotal, setSubtotal] = useState(0);
  const [convenienceFee, setConvenienceFee] = useState(0);
  const [deliveryFee, setDeliveryFee] = useState(0);
  const [totalPrice, setTotalPrice] = useState(0);

  // Define the convenience fee percentage
  const CONVENIENCE_FEE_PERCENTAGE = 0.03; // 3% fee

  // Address state for collecting customer address
  const [address, setAddress] = useState({
    name: '',
    email: '',
    phone: '',
    line1: '',
    line2: '',
    city: '',
    state: '',
    postal_code: '',
    country: '',
  });

  const handleAddressChange = (e) => {
    const { name, value } = e.target;
    setAddress((prevAddress) => ({
      ...prevAddress,
      [name]: value,
    }));

    // Update delivery fee when city changes
    if (name === 'city') {
      const cityValue = value.toLowerCase();

      if (cityValue.includes('new york') || cityValue.includes('ny')) {
        setDeliveryFee(25);
      } else if (cityValue.includes('jersey city')) {
        setDeliveryFee(15);
      } else {
        setDeliveryFee(0); // No delivery fee for other locations
      }
    }
  };

  // Redirect to home page if cart is empty
  useEffect(() => {
    if (cartItems.length === 0) {
      navigate('/');
    } else {
      // Calculate subtotal
      const subtotalAmount = cartItems.reduce(
        (acc, item) => acc + item.price * item.quantity,
        0
      );
      setSubtotal(subtotalAmount);

      // Calculate convenience fee
      const fee = subtotalAmount * CONVENIENCE_FEE_PERCENTAGE;
      setConvenienceFee(fee);

      // Calculate total price
      const total = subtotalAmount + fee + deliveryFee;
      setTotalPrice(total);

      // Create PaymentIntent
      fetch('/.netlify/functions/create-payment-intent', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ items: cartItems, city: address.city }),
      })
        .then((res) => res.json())
        .then((data) => {
          if (data.clientSecret) {
            setClientSecret(data.clientSecret);
          } else {
            console.error('Client Secret not found in response:', data);
          }
        })
        .catch((error) => {
          console.error('Error creating PaymentIntent:', error);
        });
    }
  }, [cartItems, deliveryFee, address.city, navigate]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsProcessing(true);

    if (!stripe || !elements) {
      setIsProcessing(false);
      return;
    }

    const result = await stripe.confirmCardPayment(clientSecret, {
      payment_method: {
        card: elements.getElement(CardElement),
        billing_details: {
          name: address.name,
          email: address.email,
          phone: address.phone,
          address: {
            line1: address.line1,
            line2: address.line2,
            city: address.city,
            state: address.state,
            postal_code: address.postal_code,
            country: address.country,
          },
        },
      },
      shipping: {
        name: address.name,
        phone: address.phone,
        address: {
          line1: address.line1,
          line2: address.line2,
          city: address.city,
          state: address.state,
          postal_code: address.postal_code,
          country: address.country,
        },
      },
    });

    if (result.error) {
      console.error(result.error.message);
      alert(`Payment failed: ${result.error.message}`);
      setIsProcessing(false);
    } else {
      if (result.paymentIntent.status === 'succeeded') {
        console.log('Payment succeeded!');

        // Prepare order details
        const orderDetails = {
          customerName: address.name,
          email: address.email,
          phone: address.phone || '',
          shippingAddress: `${address.line1}, ${address.line2}, ${address.city}, ${address.state}, ${address.postal_code}, ${address.country}`,
          items: cartItems,
          totalAmount: parseFloat(totalPrice.toFixed(2)), // Ensure it's a number
          paymentIntentId: result.paymentIntent.id,
        };

        // Save order to Airtable
        fetch('/.netlify/functions/save-order', {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ orderDetails }),
        })
          .then((res) => res.json())
          .then((data) => {
            if (data.success) {
              clearCart();
              navigate('/success');
            } else {
              alert('There was an error saving your order. Please contact support.');
              setIsProcessing(false);
            }
          })
          .catch((error) => {
            console.error('Error saving order:', error);
            alert('There was an error saving your order. Please contact support.');
            setIsProcessing(false);
          });
      } else {
        // Handle other statuses if necessary
        setIsProcessing(false);
      }
    }
  };

  return (
    <div className="checkout-page">
      <div className="checkout-container">
        {/* Shipping Details Section */}
        <div className="shipping-details">
          <h3>Shipping Information</h3>
          <p>
            We ship mini cupcakes, mini pies, and regular-size pies directly to your door. Please allow up to one week for delivery.
          </p>
          <p>
            For regular cupcakes and cakes, we offer personal delivery. We'll contact you to arrange a convenient delivery time.
          </p>
        </div>

        {/* Checkout Content */}
        <div className="checkout-content">
          <h2>Checkout</h2>
          <div className="cart-items">
            {cartItems.map((item) => (
              <CartItem key={item.uniqueKey} item={item} />
            ))}
          </div>
          {/* Display Subtotal, Delivery Fee, Convenience Fee, and Total Price */}
          <div className="price-summary">
            <h3>Subtotal: ${subtotal.toFixed(2)}</h3>
            <h3>Delivery Fee: ${deliveryFee.toFixed(2)}</h3>
            <h3>Convenience Fee (3%): ${convenienceFee.toFixed(2)}</h3>
            <h2>Total: ${totalPrice.toFixed(2)}</h2>
          </div>
          <form onSubmit={handleSubmit} className="checkout-form">
            {/* Address Fields */}
            <h3>Shipping Address</h3>
            <input
              type="text"
              name="name"
              placeholder="Full Name"
              value={address.name || ''}
              onChange={handleAddressChange}
              required
            />
            <input
              type="email"
              name="email"
              placeholder="Email"
              value={address.email || ''}
              onChange={handleAddressChange}
              required
            />
            <input
              type="tel"
              name="phone"
              placeholder="Phone Number"
              value={address.phone || ''}
              onChange={handleAddressChange}
              required
            />
            <input
              type="text"
              name="line1"
              placeholder="Address Line 1"
              value={address.line1}
              onChange={handleAddressChange}
              required
            />
            <input
              type="text"
              name="line2"
              placeholder="Apt/PO. Box"
              value={address.line2}
              onChange={handleAddressChange}
            />
            <input
              type="text"
              name="city"
              placeholder="City"
              value={address.city}
              onChange={handleAddressChange}
              required
            />
            <input
              type="text"
              name="state"
              placeholder="State"
              value={address.state}
              onChange={handleAddressChange}
              required
            />
            <input
              type="text"
              name="postal_code"
              placeholder="Postal Code"
              value={address.postal_code}
              onChange={handleAddressChange}
              required
            />
            <input
              type="text"
              name="country"
              placeholder="Country (US, CA)"
              value={address.country}
              onChange={handleAddressChange}
              required
            />
            {/* Payment Details */}
            <CardElement className="card-element" />
            <button
              type="submit"
              disabled={!stripe || !clientSecret || isProcessing}
              className="submit-button"
            >
              {isProcessing ? 'Processing...' : 'Pay Now'}
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Checkout;
