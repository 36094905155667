// src/components/TermsOfService.js

import React from 'react';
import '../styles/LegalPages.css';

const TermsOfService = () => {
  return (
    <div className="legal-page">
      <h1>Terms of Service</h1>
      <p>
        Agreement between User and www.deydeybakes.com 
        Welcome to www.deydeybakes.com. The www.deydeybakes.com website (the “Site”) is comprised of various web pages operated by DeyDey Bakes LLC (“DeyDey Bakes LLC”). www.deydeybakes.com is offered to you conditioned on your acceptance without modification of the terms, conditions, and notices contained herein (the “Terms”). Your use of www.deydeybakes.com constitutes your agreement to all such Terms. Please read these terms carefully, and keep a copy of them for your reference. 
      </p>
      <p>
        www.deydeybakes.com is an E-Commerce Site.
      </p>
      <h2>OVERVIEW</h2>
      <p>
        WARNING: CONSULT A PHYSICIAN BEFORE USE. DO NOT COMBINE WITH ALCOHOL, DRUGS OR MEDICATIONS. DO NOT USE IF PREGNANT, NURSING, OR HAVE ANY HEALTH CONDITIONS. MAY CAUSE DROWSINESS. DO NOT OPERATE A VEHICLE OR HEAVY MACHINERY. MAY HAVE A DELAYED EFFECT. FOR USE BY ADULTS AGE 21 AND UP. KEEP OUT OF REACH OF CHILDREN AND PETS. DO NOT REDISTRIBUTE. DeyDey Bakes LLC IS NOT RESPONSIBLE FOR THE ACTIONS OF INDIVIDUALS WHO TAKE THIS PRODUCT. THESE STATEMENTS HAVE NOT BEEN EVALUATED BY THE FOOD AND DRUG ADMINISTRATION. THIS PRODUCT IS NOT INTENDED TO DIAGNOSE, TREAT, CURE OR PREVENT ANY DISEASE.
      </p>
      <p>
        By continuing to use this site you release DeyDey Bakes LLC and all its affiliates and brands from liability resulting from misuse and/or failure to inform your medical professionals of your use and manner of use. This product is not intended to diagnose, treat, cure, or prevent any disease. CALIFORNIA PROPOSITION 65 – WARNING: This product may contain chemicals known to the State of California to cause cancer, birth defects, or other reproductive harm.
      </p>
      {/* Add more sections here as needed, following the same pattern */}
      <h2>General Conditions</h2>
      <p>
        You must be at least the legal smoking age in your state, city, province or country, whichever is older, to use this site or the Services. We reserve the right to refuse service to anyone for any reason at any time.
      </p>
      <h2>Modifications to Services and Prices</h2>
      <p>
        Prices for our products are subject to change without notice. We reserve the right at any time to modify or discontinue the Services (or any part or content thereof) without notice at any time.
      </p>
      <h2>Indemnification</h2>
      <p>
        You agree to indemnify, defend and hold harmless DeyDey Bakes LLC and our affiliates from any claim or demand made by any third-party due to or arising out of your use of the Services or by your breach of these Terms of Service.
      </p>
      {/* Continue adding the remaining content in the same manner */}
      <p>
        Effective as of January 2024. For any questions about these Terms of Service, please contact us at info@deydeybakes.com.
      </p>
    </div>
  );
};

export default TermsOfService;
