// src/App.js
import React, { useState, useEffect } from 'react'; // Added useState import
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import HomePage from './pages/HomePage';
import ProductsPage from './pages/ProductsPage';
import THCPage from './pages/THCPage';
import Checkout from './pages/Checkout'; // Import Checkout component
import MeetTheBaker from './pages/MeetTheBaker';
import ReviewsPage from './pages/ReviewsPage';
import OurCreations from './pages/OurCreations'
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import Success from './pages/Success';
import FAQs from "./pages/FAQs";
import Cancel from './pages/Cancel';
import Cart from './components/Cart';
import TermsOfService from './components/TermsOfService';
import PrivacyPolicy from './components/PrivacyPolicy';
import FdaDisclaimer from './components/FdaDisclaimer';
import ScrollToTop from './components/ScrollToTop';
import { HelmetProvider } from 'react-helmet-async';
import { CartProvider } from './components/context/CartContext';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'; // Import CSS
import './App.css';
import './styles/Toast.css';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

function App() {
  const [isCartOpen, setIsCartOpen] = useState(false);

  const toggleCart = () => {
    setIsCartOpen(!isCartOpen);
  };

  useEffect(() => {
    // IntersectionObserver callback
    const handleIntersect = (entries, observer) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          entry.target.classList.add('in-view');
          observer.unobserve(entry.target); // Optional: Remove observer after animation
        }
      });
    };

    // Create IntersectionObserver
    const observerOptions = {
      threshold: 0.3, // Trigger when 20% of the element is visible
    };

    const intersectionObserver = new IntersectionObserver(handleIntersect, observerOptions);

    // Function to observe elements
    const observeElements = (elements) => {
      elements.forEach(el => intersectionObserver.observe(el));
    };

    // Observe existing elements with 'animate-on-scroll'
    const initialElements = document.querySelectorAll('.animate-on-scroll');
    observeElements(initialElements);

    // MutationObserver callback to detect new elements
    const handleMutations = (mutationsList) => {
      for (let mutation of mutationsList) {
        if (mutation.type === 'childList') {
          mutation.addedNodes.forEach(node => {
            if (node.nodeType === 1) { // Ensure node is an element
              if (node.classList.contains('animate-on-scroll')) {
                intersectionObserver.observe(node);
              }
              // Also check descendants
              const descendants = node.querySelectorAll('.animate-on-scroll');
              if (descendants.length > 0) {
                observeElements(descendants);
              }
            }
          });
        }
      }
    };

    // Create MutationObserver
    const mutationObserver = new MutationObserver(handleMutations);

    // Start observing the entire document body for added nodes
    mutationObserver.observe(document.body, { childList: true, subtree: true });

    // Cleanup on unmount
    return () => {
      intersectionObserver.disconnect();
      mutationObserver.disconnect();
    };
  }, []);

  return (
    <HelmetProvider>
      <CartProvider>
        <GoogleReCaptchaProvider reCaptchaKey="6LcxMVUqAAAAAO_uWRk-97rbhgy-88RtKf2DVDxr">
        <Elements stripe={stripePromise}>
          <Router>
          <ScrollToTop />
            <Navbar toggleCart={toggleCart} />
            {isCartOpen && <Cart toggleCart={toggleCart} isOpen={isCartOpen} />}
            <Routes>
              <Route path="/" element={<HomePage />} />
              <Route path="/meetthebaker" element={<MeetTheBaker />} />
              <Route path="/ourcreations" element={<OurCreations />} />
              <Route path="/products" element={<ProductsPage />} />
              <Route path="/thc" element={<THCPage />} />
              <Route path="/reviews" element={<ReviewsPage />} />
              <Route path="/checkout" element={<Checkout />} />
              <Route path="/success" element={<Success />} />
              <Route path="/cancel" element={<Cancel />} />
              <Route path="/terms-of-service" element={<TermsOfService />} />
              <Route path="/privacy-policy" element={<PrivacyPolicy />} />
              <Route path="/fda-disclaimer" element={<FdaDisclaimer />} />
              <Route path="/faqs" element={<FAQs />} />
            </Routes>
            <Footer />
          </Router>
          </Elements>
        </GoogleReCaptchaProvider>
        <ToastContainer
          position="top-right" // You can choose position
          autoClose={3000} // Duration in milliseconds
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          pauseOnHover
          draggable
        />
      </CartProvider>
    </HelmetProvider>
  );
}

export default App;