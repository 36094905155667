// src/components/Products/SeasonalBanner.js
import React from 'react';
import '../../styles/Products/SeasonalBanner.css';
import bannerImage from '../../assets/images/halloweenbanner.png'; // Ensure the path is correct

const SeasonalBanner = () => {
  return (
    <div className="seasonal-banner">

      <div className="banner-text">
        <h2>Happy Halloween!</h2>
        <p>Enjoy our limited-time seasonal treats before they're gone.</p>
      </div>
    </div>
  );
};

export default SeasonalBanner;