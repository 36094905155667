// src/components/QuoteForm.js

import React, { useState } from 'react';
import '../../styles/Products/QuoteForm.css';
import ConfirmationModal from '../ConfirmationModal';

const QuoteForm = ({
  onClose,
  product,
  selectedFlavor,
  selectedFilling,
  selectedSize,
}) => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    type: product ? product.name : '',
    size: selectedSize ? selectedSize.size : '',
    flavor: selectedFlavor || '',
    filling: selectedFilling || '',
    buttercream:
      product && product.buttercreamFlavors
        ? product.buttercreamFlavors[0]
        : '',
    plaque: false,
    fondant: false,
    specialRequests: '',
    inspirationImageBase64: '',
    inspirationImageMimeType: '', // Added to store the MIME type
  });

  const [showConfirmation, setShowConfirmation] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleChange = (e) => {
    const { name, value, type, checked, files } = e.target;
  
    if (type === 'file') {
      const file = files[0];
      if (file) {
        // Validate file size (e.g., limit to 2MB)
        if (file.size > 2 * 1024 * 1024) {
          alert('File size must be less than 2MB');
          return;
        }
  
        // Validate file type (ensure it's an image)
        const validImageTypes = ['image/jpeg', 'image/png', 'image/gif'];
        if (!validImageTypes.includes(file.type)) {
          alert('Only JPEG, PNG, and GIF image files are allowed');
          return;
        }
  
        // Read the file as base64
        const reader = new FileReader();
        reader.onloadend = () => {
          const base64String = reader.result.split(',')[1]; // Remove data:*/*;base64, prefix
          const mimeType = file.type; // Get the MIME type
          setFormData({
            ...formData,
            inspirationImageBase64: base64String,
            inspirationImageMimeType: mimeType,
          });
        };
        reader.readAsDataURL(file);
      }
    } else if (type === 'checkbox') {
      setFormData({ ...formData, [name]: checked });
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    // Prepare data to send to Netlify function
    const payload = {
      ...formData,
    };

    try {
      const response = await fetch('/.netlify/functions/save-quote', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(payload),
      });

      if (!response.ok) {
        const errorText = await response.text();
        console.error('Server error:', errorText);
        alert('There was an error submitting your request. Please try again.');
        setIsSubmitting(false);
        return;
      }

      const result = await response.json();

      if (result.success) {
        setShowConfirmation(true);
        // Reset form fields
        setFormData({
          name: '',
          email: '',
          phone: '',
          type: product ? product.name : '',
          size: selectedSize ? selectedSize.size : '',
          flavor: selectedFlavor || '',
          filling: selectedFilling || '',
          buttercream:
            product && product.buttercreamFlavors
              ? product.buttercreamFlavors[0]
              : '',
          plaque: false,
          fondant: false,
          specialRequests: '',
          inspirationImageBase64: '',
          inspirationImageMimeType: '',
        });
      } else {
        console.error('Submission error:', result.error);
        alert('There was an error submitting your request. Please try again.');
      }
    } catch (error) {
      console.error('Error submitting form:', error);
      alert('There was an error submitting your request. Please try again.');
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="quote-form-modal">
      <div className="quote-form-container">
        {/* Close Button */}
        <button
          onClick={onClose}
          className="close-button"
          aria-label="Close Quote Form"
        >
          X
        </button>

        {/* Form Header */}
        <div className="quote-form-header">
          <h2>Request a Quote</h2>
          <p>
            Please fill out the form below to request a quote. Note that a{' '}
            <b>10% deposit</b> is required once the quote has been accepted.
          </p>
        </div>

        {/* Quote Form */}
        <form onSubmit={handleSubmit} className="quote-form">
          {product && <h3>Request a Quote for {product.name}</h3>}

          {/* Name Field */}
          <label htmlFor="name">Your Name</label>
          <input
            type="text"
            name="name"
            id="name"
            value={formData.name}
            onChange={handleChange}
            required
          />

          {/* Email Field */}
          <label htmlFor="email">Your Email</label>
          <input
            type="email"
            name="email"
            id="email"
            value={formData.email}
            onChange={handleChange}
            required
          />

          {/* Phone Field */}
          <label htmlFor="phone">Your Phone Number</label>
          <input
            type="tel"
            name="phone"
            id="phone"
            value={formData.phone}
            onChange={handleChange}
            required
          />

          {/* Size Selection */}
          {product && product.sizes && product.sizes.length > 0 && (
            <>
              <label htmlFor="size">Choose a size:</label>
              <select
                name="size"
                id="size"
                value={formData.size}
                onChange={handleChange}
                required
              >
                {product.sizes.map((sizeOption) => (
                  <option key={sizeOption.id} value={sizeOption.size}>
                    {sizeOption.size} - ${sizeOption.price}
                  </option>
                ))}
              </select>
            </>
          )}

          {/* Flavor Selection */}
          {product && product.flavors && product.flavors.length > 0 && (
            <>
              <label htmlFor="flavor">Choose a flavor:</label>
              <select
                name="flavor"
                id="flavor"
                value={formData.flavor}
                onChange={handleChange}
                required
              >
                {product.flavors.map((flavor, index) => (
                  <option key={index} value={flavor}>
                    {flavor}
                  </option>
                ))}
              </select>
            </>
          )}

          {/* Filling Selection */}
          {product && product.fillings && product.fillings.length > 0 && (
            <>
              <label htmlFor="filling">Choose a filling: (+$10)</label>
              <select
                name="filling"
                id="filling"
                value={formData.filling}
                onChange={handleChange}
              >
                {product.fillings.map((filling, index) => (
                  <option key={index} value={filling}>
                    {filling}
                  </option>
                ))}
              </select>
            </>
          )}

          {/* Buttercream Selection */}
          {product &&
            product.buttercreamFlavors &&
            product.buttercreamFlavors.length > 0 && (
              <>
                <label htmlFor="buttercream">
                  Choose a buttercream flavor:
                </label>
                <select
                  name="buttercream"
                  id="buttercream"
                  value={formData.buttercream}
                  onChange={handleChange}
                  required
                >
                  {product.buttercreamFlavors.map((flavor, index) => (
                    <option key={index} value={flavor}>
                      {flavor}
                    </option>
                  ))}
                </select>
              </>
            )}

          {/* Special Requests */}
          <label htmlFor="specialRequests">Special Requests</label>
          <textarea
            name="specialRequests"
            id="specialRequests"
            value={formData.specialRequests}
            onChange={handleChange}
            placeholder="Enter any special requests here"
          />

          {/* Plaque Option */}
          <div className="plaque-option">
            <label>
              <input
                type="checkbox"
                name="plaque"
                checked={formData.plaque}
                onChange={handleChange}
              />
              Add Plaque (+$5)
            </label>
          </div>

          {/* Fondant Option */}
          <div className="fondant-option">
            <label>
              <input
                type="checkbox"
                name="fondant"
                checked={formData.fondant}
                onChange={handleChange}
              />
              Add Fondant (+$15)
            </label>
          </div>

          {/* Inspiration Image Upload */}
          <label htmlFor="inspirationImage">
            Upload an Inspiration Picture
          </label>
          <input
            type="file"
            name="inspirationImage"
            id="inspirationImage"
            accept="image/*"
            onChange={handleChange}
          />

          {/* Submit Button */}
          <button type="submit" disabled={isSubmitting}>
            {isSubmitting ? 'Submitting...' : 'Submit Quote Request'}
          </button>
        </form>
      </div>

      {/* Confirmation Modal */}
      {showConfirmation && (
        <ConfirmationModal
          onClose={() => {
            setShowConfirmation(false);
            onClose(); // Close the quote form modal
          }}
        />
      )}
    </div>
  );
};

export default QuoteForm;
