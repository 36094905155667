// src/components/PrivacyPolicy.js

import React from 'react';
import '../styles/LegalPages.css';

const PrivacyPolicy = () => {
  return (
    <div className="legal-page">
      <h1>Privacy Policy</h1>
      <p>
        Protecting your private information is our priority. This Statement of Privacy applies to www.deydeybakes.com and DeyDey Bakes LLC Service and governs data collection and usage. By using the DeyDey Bakes LLC website, you consent to the data practices described in this statement.
      </p>
      <h2>Collection of your Personal Information</h2>
      <p>
        We do not collect any personal information about you unless you voluntarily provide it to us. However, you may be required to provide certain personal information to us when you elect to use certain products or services available on the Site.
      </p>
      <h2>Sharing Information with Third Parties</h2>
      <p>
        DeyDey Bakes LLC does not sell, rent or lease its customer lists to third parties. We may share data with trusted partners to help perform statistical analysis, send you email or postal mail, provide customer support, or arrange for deliveries.
      </p>
      <h2>Automatically Collected Information</h2>
      <p>
        Information about your computer hardware and software may be automatically collected by DeyDey Bakes LLC. This can include your IP address, browser type, domain names, access times, and referring website addresses.
      </p>
      <h2>Security of your Personal Information</h2>
      <p>
        DeyDey Bakes LLC secures your personal information from unauthorized access, use, or disclosure. We use SSL Protocol and strive to take appropriate security measures to protect your data.
      </p>
      <h2>Right to Deletion</h2>
      <p>
        Subject to certain exceptions, we will delete your personal information upon request. However, we may not be able to comply if it is necessary to complete a transaction, detect security incidents, or comply with legal obligations.
      </p>
      <h2>Age Restrictions on Access and Purchases</h2>
      <p>
        You must be at least 21 years old to access this site. If we learn that we have collected personal information from a person under the age of 21 without verification of parental consent, we will delete that information.
      </p>
      <h2>Changes to this Statement</h2>
      <p>
        DeyDey Bakes LLC reserves the right to change this Privacy Policy from time to time. We will notify you about significant changes by sending a notice to the primary email address in your account, by placing a prominent notice on our site, and/or by updating any privacy information on this page.
      </p>
      <h2>Contact Information</h2>
      <p>
        DeyDey Bakes LLC welcomes your questions or comments regarding this Privacy Policy. If you believe that we have not adhered to this Policy, please contact us at:
      </p>
      <p>
        DeyDey Bakes LLC<br />
        Email Address: info@deydeybakes.com<br />
        Telephone number: (929) 383-1003
      </p>
      <p>
        Effective as of January 2024.
      </p>
    </div>
  );
};

export default PrivacyPolicy;