// src/components/Cart.js

import React, { useContext } from 'react';
import { CartContext } from './context/CartContext';
import '../styles/Cart.css';
import { formatPrice } from '../utils/formatPrice';
import { useNavigate } from 'react-router-dom'; // Import useNavigate

const Cart = ({ toggleCart, isOpen }) => {
  const { cartItems, removeFromCart, updateQuantity, clearCart } = useContext(CartContext);

  const totalPrice = cartItems.reduce(
    (total, item) => total + item.price * item.quantity,
    0
  );

  // Inside the Cart component
  const navigate = useNavigate();
  
  const handleCheckout = () => {
    navigate('/checkout');
    toggleCart(); // Close the cart
  };

  return (
    <>
      {isOpen && <div className="cart-overlay" onClick={toggleCart}></div>}
      <div className={`cart-dropdown ${isOpen ? 'active' : ''}`}>
        <div className="cart-header">
          <h2>Your Cart</h2>
          <button onClick={toggleCart} className="close-cart-button" aria-label="Close Cart">
            &times;
          </button>
        </div>
        {cartItems.length === 0 ? (
          <p className="cart-empty">Your cart is empty.</p>
        ) : (
          <>
            <div className="cart-items">
              {cartItems.map((item) => (
                <div key={item.uniqueKey} className="cart-item">
                  <img src={item.image} alt={item.name} />
                  <div className="cart-item-details">
                    <p className="item-name">{item.name}</p>
                    {item.selectedSize && <p>Size: {item.selectedSize.size}</p>}
                    {item.selectedFlavor && <p>Flavor: {item.selectedFlavor}</p>}
                    {item.selectedFilling && <p>Filling: {item.selectedFilling}</p>}
                    {!item.selectedFilling && <p>Filling: None</p>}
                    <div className="cart-controls">
                      <input
                        type="number"
                        value={item.quantity}
                        min="1"
                        onChange={(e) =>
                          updateQuantity(item.uniqueKey, parseInt(e.target.value))
                        }
                      />
                      <p className="item-total">{formatPrice(item.price * item.quantity)}</p>
                      <button onClick={() => removeFromCart(item.uniqueKey)}>Remove</button>
                    </div>
                  </div>
                </div>
              ))}
            </div>
            <p className="total-price">Total: {formatPrice(totalPrice)}</p>
            <button onClick={handleCheckout} className="checkout-button">
              Checkout
            </button>
            <button onClick={clearCart} className="clear-cart-button">
              Clear Cart
            </button>
          </>
        )}
      </div>
    </>
  );
};

export default Cart;