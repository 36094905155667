// src/components/THC/ProductSEO.js
import React from 'react';
import { Helmet } from 'react-helmet-async';
import { thcProducts } from '../../data/thcProducts';

const ProductSEO = () => {
  const schemaData = thcProducts.map((product) => ({
    "@context": "https://schema.org/",
    "@type": "Product",
    "name": product.name,
    "image": `https://www.deydeybakes.com${product.image}`,
    "description": product.description,
    "sku": `DEYDEY-${product.id}`,
    "offers": {
      "@type": "Offer",
      "url": `https://www.deydeybakes.com/thc-edibles#product-${product.id}`,
      "priceCurrency": "USD",
      "price": product.price,
      "availability": "https://schema.org/InStock",
      "itemCondition": "https://schema.org/NewCondition"
    }
  }));

  return (
    <Helmet>
      <script type="application/ld+json">
        {JSON.stringify(schemaData)}
      </script>
    </Helmet>
  );
};

export default ProductSEO;