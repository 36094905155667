// src/Carousel.js

import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import '../../styles/Carousel.css';
import { useCart } from '../context/CartContext';
import { formatPrice } from '../../utils/formatPrice';

// Import seasonal products from Products.js
import { seasonal } from '../../data/Products';

const Carousel = () => {
  const { addToCart } = useCart();

  const settings = {
    dots: true,
    infinite: true,
    autoplay: true,
    speed: 900,
    centerMode: true,
    centerPadding: '40px', // Adjust as needed
    slidesToShow: 2, // Show 2 slides at a time
    slidesToScroll: 1, // Scroll 1 slide at a time
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          centerPadding: '10px',
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerMode: false, // Disable centerMode on mobile for better layout
          dots: false,
        },
      },
    ],
  };

  // Handle Add to Cart Action
  const handleAddToCart = (item) => {
    // Define uniqueKey for seasonal items to prevent duplication
    const uniqueKey = `seasonal-${item.id}`;

    // Create a cart item object with necessary fields
    const cartItem = {
      id: item.id,
      name: item.name,
      price: item.price, // Ensure this is a number
      image: item.image,
      category: item.category,
      quantity: 1,
      uniqueKey, // Unique identifier
      // Optional: additional fields can be set to null or default
      selectedFlavor: null,
      selectedFilling: null,
      selectedSize: null,
      quoteOnly: item.quoteOnly || false,
    };

    addToCart(cartItem);
  };

  return (
    <div className="carousel-container">
      <h2>SEASONAL HITS</h2>
      <Slider {...settings} className="carousel">
        {seasonal.map((item) => (
          <div key={item.id} className="carousel-item">
            <img src={item.image} alt={item.name} loading="lazy" />
            <div className="item-info">
              <h3>{item.name}</h3>
              <p className="price">{formatPrice(item.price)}</p>
              <p className="description">{item.description}</p>
              <button
                className="add-to-cart-button"
                onClick={() => handleAddToCart(item)}
                aria-label={`Add ${item.name} to cart`}
              >
                Add to Cart
              </button>
            </div>
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default Carousel;