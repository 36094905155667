// src/utils/splitText.js
import React from 'react';

/**
 * Splits a given text into an array of <span> elements, each containing a single character.
 * Adds a slight random delay to each span for a natural bobbing effect.
 * Spaces are handled separately to maintain proper spacing.
 *
 * @param {string} text - The text to split.
 * @param {number} baseDelay - The base delay in seconds for the animation.
 * @returns {JSX.Element[]} - An array of <span> elements.
 */
const splitTextIntoSpans = (text, baseDelay = 0.1) => {
  return text.split('').map((char, index) => {
    if (char === ' ') {
      return (
        <span key={index} className="letter space">
          &nbsp;
        </span>
      );
    }
    const randomDelay = (Math.random() * 0.5).toFixed(2); // Random delay between 0s and 0.5s
    return (
      <span
        key={index}
        className="letter"
        style={{ animationDelay: `${baseDelay * index + parseFloat(randomDelay)}s` }}
      >
        {char}
      </span>
    );
  });
};

export default splitTextIntoSpans;