// src/components/FdaDisclaimer.js

import React from 'react';
import '../styles/LegalPages.css'; // Make sure to import the shared CSS for legal pages

const FdaDisclaimer = () => {
  return (
    <div className="legal-page">
      <h1>FDA Disclaimer</h1>
      <p>
        These statements have not been evaluated by the FDA. The products offered for sale on this site are not intended to diagnose, treat, cure, mitigate or prevent any disease and/or affect any structure or function of the human body.
      </p>
      <h2>State Legality</h2>
      <p>
        Regulations surrounding the products offered on this site are constantly changing. We ship our products into states where they are lawful.
      </p>
      <p>
        Additionally, we reserve the right to amend, alter, or change the list of states we do not ship to at our discretion and without notice to users of this site. Any purchaser of products offered for sale on this site assumes all risk and liability associated with the purchase, possession, and use of these products.
      </p>
    </div>
  );
};

export default FdaDisclaimer;
