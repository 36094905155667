// src/pages/HomePage.js
import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import CategoryCards from '../components/Home/CategoryCards';
import ReviewSection from '../components/Home/ReviewSection';
import CTAButtons from '../components/Home/CTAButtons';
import SubscriptionCTA from '../components/Home/SubscriptionCTA';
import InstagramFeed from '../components/Home/InstagramFeed';
import Carousel from '../components/Home/Carousel';
import splitTextIntoSpans from '../utils/splitText'; // Import the helper function
import '../styles/HomePage.css';
import backgroundImage from '../assets/images/darkbg.png';
import SEO from '../components/Home/SEO'; // Import the SEO component
import News from '../components/News/News';
const HomePage = () => {
  useEffect(() => {
    const setVh = () => {
      const vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', `${vh}px`);
    };
    setVh();
    window.addEventListener('resize', setVh);
    return () => {
      window.removeEventListener('resize', setVh);
    };
  }, []);

  return (
    <div
      className="home-page font-bodoni"
      style={{
        backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.1)), url(${backgroundImage})`,
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundAttachment: 'fixed', // Will adjust via CSS media queries
        minHeight: 'calc(var(--vh, 1vh) * 100)', // Use the CSS variable
      }}
    >
      <SEO />
      <Helmet>
        <title>Dey-Dey Bakes | Delicious Cakes, Cupcakes & Pies</title>
        <meta
          name="description"
          content="Welcome to Dey-Dey Bakes, your premier bakery in New York and New Jersey. Explore our wide range of delicious cakes, cupcakes, and pies made with the finest ingredients."
        />
        <meta
          name="keywords"
          content="Bakery, Cakes, Cupcakes, Pies, New York Bakery, New Jersey Bakery, Custom Cakes, Dessert Shop"
        />
        {/* Open Graph Meta Tags for Social Media Sharing */}
        <meta property="og:title" content="Dey-Dey Bakes | Delicious Cakes, Cupcakes & Pies" />
        <meta
          property="og:description"
          content="Welcome to Dey-Dey Bakes, your premier bakery in New York and New Jersey. Explore our wide range of delicious cakes, cupcakes, and pies made with the finest ingredients."
        />
        <meta property="og:image" content="https://www.deydeybakes.com/assets/images/og-image.jpg" />
        <meta property="og:url" content="https://www.deydeybakes.com/" />
        <meta property="og:type" content="website" />
        {/* Twitter Card Meta Tags */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Dey-Dey Bakes | Delicious Cakes, Cupcakes & Pies" />
        <meta
          name="twitter:description"
          content="Welcome to Dey-Dey Bakes, your premier bakery in New York and New Jersey. Explore our wide range of delicious cakes, cupcakes, and pies made with the finest ingredients."
        />
        <meta name="twitter:image" content="https://www.deydeybakes.com/assets/images/twitter-image.jpg" />
      </Helmet>

      {/* Hero Section */}
      <section className="hero-section relative">
        <div className="intro-text absolute inset-0 flex flex-col justify-center items-center text-center text-white animate-on-scroll" data-animation="fade-in-left">
          <h1 className="intro-header text-8xl font-bolder">
            {splitTextIntoSpans('DEY-DEY BAKES')}
          </h1>
          <p className="text-2xl mt-2">
            Delicious treats, baked with <span><b>LOVE</b></span>
          </p>
          <CTAButtons />
        </div>
      </section>
      <div className="carousel-wrapper">
        <Carousel />
      </div>
      
      <News />
      {/* Carousel */}

      {/* Review Section */}
      <ReviewSection />
      <InstagramFeed />
    </div>
  );
};

export default HomePage;