// src/components/ConfirmationModal.js

import React from 'react';
import '../styles/ConfirmationModal.css';

const ConfirmationModal = ({ onClose }) => {
  return (
    <div className="confirmation-modal-overlay">
      <div className="confirmation-modal-container">
        <button onClick={onClose} className="close-button">×</button>
        <h2>Quote Request Submitted</h2>
        <p>
          Thank you for your request! You will receive an email with the price shortly.
        </p>
        <button onClick={onClose} className="ok-button">OK</button>
      </div>
    </div>
  );
};

export default ConfirmationModal;