// src/components/Footer.js

import React from 'react';
import { Link } from 'react-router-dom'; // Ensure react-router-dom is installed
import '../styles/Footer.css'; // Import the CSS file
import logo from '../assets/images/DEYDEYBAKESLOGO.png'; // Path to your logo
import veteranBadge from '../assets/images/veteran_badge.png'; // Path to veteran support image

const Footer = () => {
  return (
    <footer className="footer">
      {/* Footer Content */}
      <div className="footer-container">
        {/* Company Info */}
        <div className="footer-section company-info">
          <img src={logo} alt="Dey Dey Bakes Logo" className="footer-logo" />
          <p className="footer-description">
            Dey Dey Bakes offers the best cupcakes with delicious flavors. Order online and explore our THC edibles.
          </p>
        </div>

        {/* Navigation Links */}
        <div className="footer-section footer-nav">
          <h4>Quick Links</h4>
          <ul>
            <li><Link to="/">Home</Link></li>
            <li><Link to="/MeetTheBaker">Meet The Baker</Link></li>
            <li><Link to="/Products">Products</Link></li>
            <li><Link to="/thc">THC Edibles</Link></li>
          </ul>
        </div>

        {/* Veteran Support */}
        <div className="footer-section veteran-support">
          <h4>We Support Our Veterans</h4>
          <img src={veteranBadge} alt="Veteran Support" className="veteran-badge" />
          <p>
            Proudly supporting our veterans. A portion of our proceeds goes to veteran organizations.
          </p>
        </div>

        {/* Legal Links */}
        <div className="footer-section legal-links">
          <h4>Legal</h4>
          <ul>
            <li><Link to="/terms-of-service">Terms of Service</Link></li>
            <li><Link to="/privacy-policy">Privacy Policy</Link></li>
            <li><Link to="/fda-disclaimer">FDA Disclaimer</Link></li>
          </ul>
        </div>

        {/* Social Media Links */}
        <div className="footer-section social-media">
          <h4>Follow Us</h4>
          <ul className="social-icons">
            <li><a href="https://www.facebook.com/yourpage" target="_blank" rel="noopener noreferrer"><i className="fab fa-facebook-f"></i></a></li>
            <li><a href="https://www.instagram.com/yourpage" target="_blank" rel="noopener noreferrer"><i className="fab fa-instagram"></i></a></li>
            <li><a href="https://www.twitter.com/yourpage" target="_blank" rel="noopener noreferrer"><i className="fab fa-twitter"></i></a></li>
            {/* Add more social icons as needed */}
          </ul>
        </div>
      </div>

      {/* Footer Bottom */}
      <div className="footer-bottom">
        <p>&copy; {new Date().getFullYear()} Dey Dey Bakes. All Rights Reserved.</p>
      </div>
    </footer>
  );
};

export default Footer;