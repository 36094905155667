// src/components/context/CartContext.js

import React, { createContext, useContext, useReducer } from 'react';
import { v4 as uuidv4 } from 'uuid'; // Ensure you have uuid installed

// Create the Cart Context
const CartContext = createContext();

// Define initial state
const initialState = {
  cartItems: [],
};

// Define reducer to manage cart state
const cartReducer = (state, action) => {
  switch (action.type) {
    case 'ADD_TO_CART':
      // Prevent adding quote-only products to the cart
      if (action.payload.quoteOnly) {
        console.warn('Attempted to add a quote-only product to the cart.');
        return state;
      }

      const existingItem = state.cartItems.find(
        (item) => item.uniqueKey === action.payload.uniqueKey
      );

      if (existingItem) {
        // Update quantity if item already exists
        return {
          ...state,
          cartItems: state.cartItems.map((item) =>
            item.uniqueKey === action.payload.uniqueKey
              ? { ...item, quantity: item.quantity + action.payload.quantity }
              : item
          ),
        };
      } else {
        // Add new item to cart
        return {
          ...state,
          cartItems: [...state.cartItems, { ...action.payload }],
        };
      }

    case 'REMOVE_FROM_CART':
      return {
        ...state,
        cartItems: state.cartItems.filter(
          (item) => item.uniqueKey !== action.payload.uniqueKey
        ),
      };

    case 'CLEAR_CART':
      return initialState;

    case 'UPDATE_QUANTITY':
      return {
        ...state,
        cartItems: state.cartItems.map((item) =>
          item.uniqueKey === action.payload.uniqueKey
            ? { ...item, quantity: action.payload.quantity }
            : item
        ),
      };

    default:
      return state;
  }
};

// Create CartProvider to wrap the app and provide cart state
export const CartProvider = ({ children }) => {
  const [state, dispatch] = useReducer(cartReducer, initialState);

  /**
   * Adds a product to the cart.
   * @param {Object} item - The product with selected options.
   */
  const addToCart = (item) => {
    const {
      id,
      category,
      name,
      price,
      quantity = 1,
      selectedSize = null,
      selectedFlavor = null,
      selectedFilling = 'None',
      selectedButtercream = 'Vanilla',
      image = null,
      quoteOnly = false,
      uniqueKey = `${id}-${selectedSize?.id || ''}-${selectedFlavor || ''}-${selectedFilling || ''}-${selectedButtercream || ''}`,
    } = item;

    const cartItem = {
      uniqueKey,
      id,
      category,
      name,
      price,
      quantity,
      selectedSize,
      selectedFlavor,
      selectedFilling,
      selectedButtercream,
      image,
      quoteOnly,
    };

    // Debugging: Log the cartItem being added
    console.log('Adding to Cart:', cartItem);

    dispatch({ type: 'ADD_TO_CART', payload: cartItem });
  };

  const removeFromCart = (uniqueKey) => {
    dispatch({ type: 'REMOVE_FROM_CART', payload: { uniqueKey } });
  };

  const clearCart = () => {
    dispatch({ type: 'CLEAR_CART' });
  };

  const updateQuantity = (uniqueKey, quantity) => {
    if (quantity < 1) return; // Prevent quantity less than 1
    dispatch({ type: 'UPDATE_QUANTITY', payload: { uniqueKey, quantity } });
  };

  return (
    <CartContext.Provider
      value={{
        cartItems: state.cartItems,
        addToCart,
        removeFromCart,
        clearCart,
        updateQuantity,
      }}
    >
      {children}
    </CartContext.Provider>
  );
};

// Custom hook to use the CartContext
export const useCart = () => {
  const context = useContext(CartContext);
  if (!context) {
    throw new Error('useCart must be used within a CartProvider');
  }
  return context;
};

// Export CartContext for components that need direct access
export { CartContext };
