// src/components/News/News.js

import React, { useState, useEffect } from "react";
import styles from "../../styles/News/News.module.css";
import newsData from "../../data/newsData";
import NewsModal from "./NewsModal";
import { FaTimes } from "react-icons/fa"; // For the close button

const News = () => {
  // Updated state to store both image src and its index
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [expandedImage, setExpandedImage] = useState(null); // { src: '...', index: 0 }

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  // Updated function to accept image index
  const openImageModal = (image, index) => {
    setExpandedImage({ src: image, index: index });
    setIsModalOpen(true); // Open the modal when an image is clicked
  };

  const closeImageModal = () => {
    setExpandedImage(null);
    setIsModalOpen(false); // Close the modal
  };

  const newsItem = newsData[0]; // Displaying the first news item for now

  // Handle Escape key to close the modal
  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.key === "Escape") {
        closeImageModal();
      }
    };
    if (isModalOpen) {
      window.addEventListener("keydown", handleKeyDown);
    } else {
      window.removeEventListener("keydown", handleKeyDown);
    }
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [isModalOpen]);

  return (
    <section className={styles.newsSection}>
      <h2 className={styles.sectionTitle}>NEWS & UPDATES</h2>
      <div className={styles.newsGrid}>
        {/* Description Section */}
        <div className={styles.newsDescription}>
          <h3>{newsItem.title}</h3>
          <p>{newsItem.date}</p>
          <p>{newsItem.description}</p>
          <button className={styles.readMoreButton} onClick={openModal}>
            Read More
          </button>
        </div>

        {/* Image Grid */}
        {newsItem.images.map((image, index) => (
          <div
            key={index}
            className={`${styles.newsItem} ${styles[`newsItem${index + 1}`]}`}
          >
            <img
              src={image}
              alt={`${newsItem.title} Image ${index + 1}`}
              className={styles.newsImage}
              onClick={() => openImageModal(image, index)}
            />
          </div>
        ))}
      </div>
      <div className={styles.viewAllContainer}>
        <button className={styles.viewAllButton} onClick={openModal}>
          View All
        </button>
      </div>
      {isModalOpen && <NewsModal onClose={closeModal} newsData={newsData} />}

      {/* Expanded Image Modal */}
      {expandedImage && (
        <div
          className={`${styles.imageModal} ${isModalOpen ? styles.open : ""}`}
          onClick={closeImageModal}
        >
          <div
            className={`${styles.imageModalContent} ${
              expandedImage.index === 2 ? styles.image3 : ""
            }`}
            onClick={(e) => e.stopPropagation()} // Prevent closing when clicking inside the content
            role="dialog"
            aria-modal="true"
            aria-labelledby="expandedImageTitle"
          >
            <img src={expandedImage.src} alt="Expanded View" />
            <button
              className={styles.closeButton}
              onClick={closeImageModal}
              aria-label="Close Image Modal"
            >
              <FaTimes />
            </button>
          </div>
        </div>
      )}
    </section>
  );
};

export default News;