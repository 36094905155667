// src/pages/Success.js

import React from 'react';
import { Link } from 'react-router-dom';
import '../styles/Success.css';

const Success = () => {
  return (
    <div className="success-container">
      <div className="success-card">
        <h1>Thank You for Your Purchase!</h1>
        <p>Your transaction was successful. We appreciate your business!</p>
        <p>A confirmation email has been sent to your email address.</p>
        <Link to="/" className="home-button">
          Continue Shopping
        </Link>
      </div>
    </div>
  );
};

export default Success;
