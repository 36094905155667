// src/components/Home/SEO.js
import React from 'react';
import { Helmet } from 'react-helmet-async';

const SEO = () => {
  const structuredData = {
    "@context": "https://schema.org",
    "@type": "Bakery",
    "name": "Dey-Dey Bakes",
    "description": "Dey-Dey Bakes is a premier bakery located in New York and New Jersey, offering a wide range of delicious cakes, cupcakes, and pies made with the finest ingredients.",
    "url": "https://www.deydeybakes.com/",
    "logo": "https://www.deydeybakes.com/assets/images/logo.png",
    "address": {
      "@type": "PostalAddress",
      "streetAddress": "123 Baker Street",
      "addressLocality": "New York",
      "addressRegion": "NY",
      "postalCode": "10001",
      "addressCountry": "US"
    },
    "telephone": "+1-555-123-4567",
    "openingHours": "Mo-Sa 08:00-20:00",
    "sameAs": [
      "https://www.facebook.com/deydeybakes",
      "https://www.instagram.com/deydeybakes",
      "https://www.twitter.com/deydeybakes"
    ]
  };

  return (
    <Helmet>
      <script type="application/ld+json">
        {JSON.stringify(structuredData)}
      </script>
    </Helmet>
  );
};

export default SEO;
