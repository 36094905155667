// src/components/ShippingProcess.js
import React from 'react';
import '../../styles/Products/ShippingProcess.css';

const ShippingProcess = () => {
  const steps = [
    {
      id: 1,
      title: 'Order Placed',
      description: 'Place your order through our website.',
      icon: 'fas fa-shopping-cart', // Font Awesome icon class
    },
    {
      id: 2,
      title: 'Baking with Love',
      description: 'We prepare your treats using fresh ingredients.',
      icon: 'fas fa-birthday-cake',
    },
    {
      id: 3,
      title: 'Delivered Fresh',
      description: 'Your order is delivered right to your doorstep within a week.',
      icon: 'fas fa-truck',
    },
  ];

  return (
    <section className="shipping-process">
      <h2 className="shipping-title">Our Shipping Process</h2>
      <div className="steps-container">
        {steps.map((step) => (
          <div key={step.id} className="step">
            <div className="step-icon">
              <i className={step.icon}></i>
            </div>
            <h3 className="step-title">{step.title}</h3>
            <p className="step-description">{step.description}</p>
          </div>
        ))}
      </div>
    </section>
  );
};

export default ShippingProcess;
