// src/components/MeetTheBaker/JourneyTimeline.js

import React from 'react';
import '../../styles/MeetTheBaker/JourneyTimeline.css';
import { useEffect, useState } from 'react';

import pastrySchoolImg from '../../assets/images/meetthebaker/ICEimage.jpg';
import externshipImg from '../../assets/images/meetthebaker/bakery.webp';
import culinarySchoolImg from '../../assets/images/meetthebaker/Institute_of_Culinary_Education_Logo.jpg';
import cityCakesImg from '../../assets/images/meetthebaker/ICEimage.jpg';
import deydedeyBakesImg from '../../assets/images/DEYDEYBAKESLOGO.png';

const JourneyTimeline = () => {
  const [isSmallScreen, setIsSmallScreen] = useState(false);
  const milestones = [
    {
      year: '2020',
      title: 'Institute of Culinary Education',
      description:
        'Enrolled in an intensive pastry arts program where Shawn mastered foundational baking techniques, explored various pastry styles, and honed her skills in cake decorating and confectionery. Participated in hands-on workshops and collaborated with peers on creative dessert projects, solidifying her passion for the culinary arts.',
      image: pastrySchoolImg,
    },
    {
      year: '2021',
      title: 'Externship at Cocoa Bakery, Hoboken, NJ',
      description:
        'Completed a six-month externship at Cocoa Bakery, gaining real-world experience in a high-paced bakery environment. Assisted in daily operations, from preparing dough and baking goods to managing inventory and customer service. Learned advanced pastry techniques and contributed to the creation of seasonal specialties, enhancing her practical skills and industry knowledge.',
      image: externshipImg,
    },
    {
      year: '2021 - 2022',
      title: 'Health Supportive Culinary School at Institute of Culinary Education',
      description:
        'Continued culinary education with a focus on health-conscious baking and dietary-specific pastries. Studied the development of gluten-free, vegan, and low-sugar desserts, ensuring high quality without compromising on taste. Collaborated on projects to create inclusive dessert menus and participated in community outreach to promote healthy eating.',
      image: culinarySchoolImg,
    },
    {
      year: '2022 - 2024',
      title: 'Pastry Chef at City Cakes, New York City',
      description:
        'Served as the lead pastry chef at City Cakes, overseeing the creation of a diverse range of desserts. Introduced a new line of gluten-free and vegan pastries, significantly expanding the bakery’s clientele. Managed a team of junior bakers, optimized production processes, and maintained high standards of quality and presentation. Played a key role in marketing efforts to promote new products and drive sales growth.',
      image: cityCakesImg,
    },
    {
      year: '2024 - Present',
      title: 'Founder of Dey Dey Bakes',
      description:
        'Founded Dey Dey Bakes, a boutique bakery specializing in handcrafted cakes and custom desserts. Leveraged industry experience to develop unique flavor profiles and intricate designs, earning a reputation for excellence. Successfully launched the business, and built a loyal customer base. Continues to innovate and expand product offerings while maintaining a commitment to quality and customer satisfaction.',
      image: deydedeyBakesImg,
    },
  ];

  // Check screen size and update state
  const checkScreenSize = () => {
    setIsSmallScreen(window.innerWidth <= 600);
  };

  useEffect(() => {
    // Add event listener to check screen size on resize
    window.addEventListener('resize', checkScreenSize);
    // Initial check
    checkScreenSize();
    // Cleanup event listener on unmount
    return () => window.removeEventListener('resize', checkScreenSize);
  }, []);

    
  return (
    <section className="journey-timeline" id="journey">
      <h2>Shawn's Baking Journey</h2>
      <div className="timeline-container">
        {milestones.map((milestone, index) => (
          <div key={index} className={`timeline-item ${isSmallScreen ? 'center' : index % 2 === 0 ? 'left' : 'right'}`}>
            <>
              <div className="timeline-content">
                <h3>{milestone.year}</h3>
                <h4>{milestone.title}</h4>
                <p>{milestone.description}</p>
              </div>
              <div className="timeline-image">
                <img src={milestone.image} alt={milestone.title} loading="lazy" />
              </div>
            </>
          </div>
        ))}
      </div>
    </section>
  );
};

export default JourneyTimeline;