// src/data/newsData.js
import treats from '../assets/images/news/treats.jpg'
import deyfriends from '../assets/images/news/deydey_friends.jpg'
import deyattable from '../assets/images/news/deyattable.jpg'
import deydey from '../assets/images/news/deywithproducts.jpg'
const newsData = [
    {
      id: 1,
      title: "The Million Woman Fund",
      date: "October 16, 2024",
      description: "The Million Woman Fund baking event is a community-driven fundraising initiative aimed at empowering women and supporting charitable causes related to women’s health, education, and entrepreneurship. The event typically features a large-scale bake sale or baking competition, where participants can showcase their baking skills by preparing a variety of treats, such as cakes, cookies, pastries, and bread. In addition to selling baked goods, the event often includes baking workshops, live demonstrations by professional bakers, and family-friendly activities. Funds raised from the event go towards initiatives that support women and girls, including scholarships, healthcare programs, and small business grants. The goal of the event is not only to raise funds but also to foster a sense of community, promote women’s leadership, and encourage culinary creativity.",
      images: [
        deyfriends,
        deyattable,
        deydey,
        treats
      ],
      category: "Video",
    }
  ];
  
  export default newsData;