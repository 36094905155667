// src/components/THC/AgeVerificationModal.js
import React from 'react';
import '../../styles/THC/AgeVerificationModal.css';

const AgeVerificationModal = ({ onVerify }) => {
  const handleYes = () => {
    onVerify(true);
  };

  const handleNo = () => {
    onVerify(false);
  };

  return (
    <div className="age-verification-overlay">
      <div className="age-verification-modal" role="dialog" aria-labelledby="age-verification-title" aria-modal="true">
        <h2 id="age-verification-title">Age Verification</h2>
        <p>Are you 21 years of age or older?</p>
        <div className="age-verification-buttons">
          <button onClick={handleYes} className="age-button yes-button">Yes</button>
          <button onClick={handleNo} className="age-button no-button">No</button>
        </div>
      </div>
    </div>
  );
};

export default AgeVerificationModal;