// src/components/MeetTheBaker/GallerySection.js

import React from 'react';
import '../styles/MeetTheBaker/GallerySection.css';
import cake1 from '../assets/images/products/unicorn_6inch.jpg';
import cake2 from '../assets/images/products/black_6inch.jpg';
import cake3 from '../assets/images/meetthebaker/3yrsold.jpg';
import cake4 from '../assets/images/meetthebaker/itsagirl.jpg';
import cake5 from '../assets/images/meetthebaker/minecraft.jpg';
import cake6 from '../assets/images/meetthebaker/liam.jpg';

const OurCreations = () => {
  const galleryImages = [
    { src: cake1, alt: 'Vanilla Unicorn Cake' },
    { src: cake2, alt: 'Dark Emo Themed Cake' },
    { src: cake3, alt: 'Birthday Cake for 3 year old Alexander' },
    { src: cake4, alt: 'Gender Reveal Cake' },
    { src: cake5, alt: 'Minecraft Movie Themed Cake' },
    { src: cake6, alt: 'Liam is a wild one gift cake' },
  ];

  return (
    <section className="gallery-section" id="gallery">
      <h2>Our Creations</h2>
      <div className="gallery-grid">
        {galleryImages.map((image, index) => (
          <div key={index} className="gallery-item">
            <img src={image.src} alt={image.alt} loading="lazy" />
            <div className="overlay">
              <div className="overlay-text">{image.alt}</div>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};

export default OurCreations;