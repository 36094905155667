// src/components/InstagramFeed.js
import React, { useEffect } from 'react';
import '../../styles/InstagramFeed.css';

const InstagramFeed = () => {
  useEffect(() => {
    // Load the Instagram embed script
    const script = document.createElement('script');
    script.async = true;
    script.src = '//www.instagram.com/embed.js';
    document.body.appendChild(script);
  }, []);

  return (
    <section className="instagram-section">
      <h2 className="instagram-title">Follow Us on Instagram</h2>
      <div className="instagram-posts">
        {/* Replace the URLs with your Instagram post URLs */}
        <blockquote
          className="instagram-media"
          data-instgrm-permalink="https://www.instagram.com/p/C323TF_OIl1/"
          data-instgrm-version="14"
        ></blockquote>
        <blockquote
          className="instagram-media"
          data-instgrm-permalink="https://www.instagram.com/p/C9QCULtPzsE/?img_index=1"
          data-instgrm-version="14"
        ></blockquote>
        <blockquote
          className="instagram-media"
          data-instgrm-permalink="https://www.instagram.com/p/Cplzn-Fu03I/"
          data-instgrm-version="14"
        ></blockquote>
          <blockquote
          className="instagram-media"
          data-instgrm-permalink="https://www.instagram.com/p/C3d4hVrvzsy/"
          data-instgrm-version="14"
        ></blockquote>
      </div>
    </section>
  );
};

export default InstagramFeed;