// src/firebase.js
import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';

// Your Firebase configuration object
const firebaseConfig = {
  apiKey: "AIzaSyDOd6zpD4ezDdhLa10ej0CUQLrVTNb2I-Q",
  authDomain: "dey-dey-bakes.firebaseapp.com",
  projectId: "dey-dey-bakes",
  storageBucket: "dey-dey-bakes.appspot.com",
  messagingSenderId: "466455882995",
  appId: "1:466455882995:web:bd94f4adbd6962c257db86",
  measurementId: "G-NK1C8WS858"
};

// Temporary logging for debugging
console.log('Firebase Config:', firebaseConfig);

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firestore
const db = getFirestore(app);

export { db };