// src/components/THC/THCHeroSection.js
import React from 'react';
import '../../styles/THC/THCHeroSection.css';
import thcBackground from '../../assets/images/thc/THCBG.png'; // Ensure this image exists

const THCHeroSection = () => {
  return (
    <section
      className="thc-hero-section"
      style={{ backgroundImage: `url(${thcBackground})` }}
    >
      <div className="thc-hero-overlay"></div>
      <div className="thc-hero-content">
        <h1 className="thc-hero-title">THC-Infused Edible Cakes</h1>
        <p className="thc-hero-description">
          Experience the perfect blend of delicious desserts and THC-infused relaxation. Our edible cakes are crafted to provide both flavor and a gentle, calming effect.
        </p>
      </div>
    </section>
  );
};

export default THCHeroSection;