import React from 'react';
import { Helmet } from 'react-helmet-async';

const ReviewsPage = () => {
  return (
    <div className="p-8 bg-redDark text-beige">
      <Helmet>
        <title>Customer Reviews - Dey Dey Bakes</title>
        <meta name="description" content="See what our customers have to say about our cupcakes and THC edibles." />
        <meta name="keywords" content="Customer reviews, Testimonials, Bakery reviews, THC edibles reviews" />
      </Helmet>

      <h1 className="text-center text-3xl font-bold">Customer Reviews</h1>
      <p className="mt-4 text-center">See what our customers have to say about Dey Dey Bakes.</p>
      {/* Add reviews dynamically */}
    </div>
  );
};

export default ReviewsPage;