// src/components/THC/THCProductList.js
import React from 'react';
import '../../styles/THC/THCProductList.css';
import { thcProducts } from '../../data/thcProducts';
import { useCart } from '../context/CartContext'; // Assuming a CartContext exists

const THCProductList = () => {
  const { addToCart } = useCart();

  return (
    <section className="thc-product-list" id="products">
      <h2>Our THC-Infused Products</h2>
      <div className="thc-products-grid">
        {thcProducts.map((product) => (
          <div key={product.id} className="thc-product-card">
            <img src={product.image} alt={`${product.name}`} className="thc-product-image" loading="lazy" />
            <h3 className="thc-product-name">{product.name}</h3>
            <p className="thc-product-description">{product.description}</p>
            <p className="thc-product-price">${product.price.toFixed(2)}</p>
            <button 
              className="add-to-cart-button" 
              onClick={() => addToCart(product)}
              aria-label={`Add ${product.name} to cart`}
            >
              Add to Cart
            </button>
          </div>
        ))}
      </div>
    </section>
  );
};

export default THCProductList;