// src/components/AboutSection.js
import React from 'react';
import '../../styles/MeetTheBaker/AboutSection.css';
import deypic from '../../assets/images/DeySelfie.jpg';

const AboutSection = () => {
  return (
    <section className="about-section">
      <div className="about-content animate-on-scroll" data-animation="fade-in-left">
        <div className="about-image">
          <img src={deypic} alt="Dey-Dey" />
        </div>
        <article  className="about-text">
          <h2>About Shawn</h2>
          <p>
          Meet Shawn, our incredible baker with a heart full of stories and a touch of magic in her hands. As a Navy veteran, Shawn seamlessly blends military precision with creative brilliance in the world of cake decorating. Having sailed the seas for years, she discovered her true passion lies in crafting edible treats.

Shawn's cakes are not just a feast for the eyes with their enchanting designs; they also embody the disciplined spirit cultivated during her dedicated service. Clients are not only captivated by her artistic skills but are drawn to the inspiring narrative of a veteran finding solace and joy in the delicate art of baking.

In Shawn's extraordinary journey, she has become a symbol of resilience and creativity, proving that life's unexpected twists can lead to the sweetest success stories. Come and celebrate the one-of-a-kind fusion of Shawn's military precision and creative brilliance, transforming each cake into a masterpiece and every bite into a delightful journey worth savoring.
          </p>
          {/* Add more paragraphs as needed */}
        </article >
      </div>
    </section>
  );
};

export default AboutSection;