// src/components/News/NewsModal.js

import React, { useState } from "react";
import styles from "../../styles/News/NewsModal.module.css";

const NewsModal = ({ onClose, newsData }) => {
  const [selectedNews, setSelectedNews] = useState(null);

  const handleNewsSelect = (news) => {
    setSelectedNews(news);
  };

  const handleBackToList = () => {
    setSelectedNews(null);
  };

  return (
    <div className={styles.modalOverlay}>
      <div className={styles.modalContent}>
        <button className={styles.closeButton} onClick={onClose}>×</button>
        {!selectedNews ? (
          <>
            <h2>All News & Events</h2>
            <div className={styles.newsList}>
              {newsData.map((news) => (
                <div
                  key={news.id}
                  className={styles.newsItem}
                  onClick={() => handleNewsSelect(news)}
                >
                  <h3>{news.title}</h3>
                  <p>{news.date}</p>
                  <p>{news.description}</p>
                </div>
              ))}
            </div>
          </>
        ) : (
          <>
            <button className={styles.closeButton} onClick={onClose}>X</button>
            <h2>{selectedNews.title}</h2>
            <p>{selectedNews.date}</p>
            <p>{selectedNews.description}</p>
            <div className={styles.selectedNewsImages}>
              {selectedNews.images.map((image, index) => (
                <img
                  key={index}
                  src={image}
                  alt={`${selectedNews.title} - ${index + 1}`}
                  className={styles.newsImage}
                />
              ))}
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default NewsModal;
