// src/pages/Cancel.js

import React from 'react';
import { Link } from 'react-router-dom';
import '../styles/Cancel.css';

const Cancel = () => {
  return (
    <div className="cancel-container">
      <div className="cancel-card">
        <h1>Payment Canceled</h1>
        <p>Your payment was not completed.</p>
        <p>You can continue shopping or try the checkout process again.</p>
        <Link to="/" className="home-button">
          Continue Shopping
        </Link>
      </div>
    </div>
  );
};

export default Cancel;