// src/components/ReviewSection.js
import React from 'react';
import '../../styles/ReviewSection.css';

const reviews = [
  { text: 'Best cupcakes ever!', author: 'Jane Doe' },
  { text: 'I love the variety of THC options!', author: 'John Smith' },
  { text: 'The flavors are out of this world!', author: 'Emily Rose' },
  { text: 'Amazing service and delicious treats!', author: 'Michael Lee' },
];

const ReviewSection = () => {
  return (
    <section className="review-section animate-on-scroll" data-animation="fade-in-left">
      <h2 className="review-title">Customer Reviews</h2>
      <p className='see-here'>See what our loyal customers say about us!</p>
      <div className="review-cards">
        {reviews.map((review, index) => (
          <div key={index} className="review-card">
            <div className="quote-icon">“</div>
            <p className="review-text">{review.text}</p>
            <span className="review-author">- {review.author}</span>
          </div>
        ))}
      </div>
    </section>
  );
};

export default ReviewSection;