// src/components/CartItem.js

import React, { useContext } from 'react';

const CartItem = ({ item }) => {

  return (
    <div className="cart-item">
      <img src={item.image} alt={item.name} className="cart-item-image" />
      <div className="cart-item-details">
        <h3>{item.name}</h3>
        {item.selectedSize && <p><strong>Size:</strong> {item.selectedSize.size}</p>}
        {item.selectedFlavor && <p><strong>Flavor:</strong> {item.selectedFlavor}</p>}
        {item.selectedFilling && (
          <p><strong>Filling:</strong> {item.selectedFilling}</p>
        )}
        {item.selectedButtercream && (
          <p><strong>Buttercream:</strong> {item.selectedButtercream}</p>
        )}
        <p><strong>Quantity:</strong> {item.quantity}</p>
        <p><strong>Price:</strong> ${item.price.toFixed(2)}</p>
      </div>
    </div>
  );
};

export default CartItem;