// src/data/faqData.js

const faqData = [
  {
    category: "General FAQs",
    faqs: [
      {
        question: "What types of products do you offer?",
        answer:
          "At Dey Dey Bakes, we specialize in custom cakes, heart-shaped cupcakes, pies, and mini pies with classic and modern flavors. We also offer cannabis-infused cupcakes for adults 21 and over, available soon through our food truck at select events.",
      },
      {
        question: "Do you offer gluten-free or vegan options?",
        answer:
          "Yes, we offer both gluten-free and vegan options for our cupcakes and cakes. Please note that we are not a fully gluten-free kitchen, so cross-contamination may occur.",
      },
      {
        question: "Where are you located?",
        answer:
          "We are an online bakery based in Jersey City, NJ. Orders can be placed through our website, and deliveries are available within the Northeast region.",
      },
      {
        question: "Do you deliver?",
        answer:
          "Yes, we deliver Wednesday through Saturday within a 15-mile radius of Jersey City. We are working on partnerships with third-party services like Uber to expand delivery options. We also ship non-perishable items like pies across the Northeast region.",
      },
    ],
  },
  {
    category: "Order & Delivery FAQs",
    faqs: [
      {
        question: "How do I place an order?",
        answer:
          "You can place your order directly through our website 24/7. Simply select the product you want, customize it, and choose your preferred delivery date.",
      },
      {
        question: "How far in advance should I place my order?",
        answer:
          "For custom cakes, we recommend placing your order at least 1-2 weeks in advance to ensure availability. For cupcakes and pies, a 3-day notice is usually sufficient.",
      },
      {
        question: "Do you offer same-day delivery?",
        answer:
          "Unfortunately, we do not offer same-day delivery at this time. Orders must be placed at least 3 days in advance.",
      },
      {
        question: "How much is delivery?",
        answer:
          "Delivery within Jersey City is free for orders over $50. For areas outside Jersey City but within a 15-mile radius, delivery fees vary based on distance and will be calculated at checkout.",
      },
    ],
  },
  {
    category: "Customization & Special Events FAQs",
    faqs: [
      {
        question: "Can I customize my cake or cupcakes?",
        answer:
          "Absolutely! We specialize in custom designs. You can choose from a variety of flavors, fillings, and decorations, and we’ll work with you to create something unique for your event.",
      },
      {
        question: "Do you cater for weddings, birthdays, or other special events?",
        answer:
          "Yes, we cater to all kinds of events, including weddings, birthdays, baby showers, and more. You can contact us for large orders or custom designs to make your event unforgettable.",
      },
      {
        question: "Do you provide samples for large orders or event planners?",
        answer:
          "Yes, if you're planning a large event or are an event planner, we offer sample boxes so you can try our products before placing your order. Contact us for more details.",
      },
    ],
  },
  {
    category: "Cannabis-Infused Product FAQs",
    faqs: [
      {
        question: "Are your cannabis-infused products legal?",
        answer:
          "Yes, our cannabis-infused cupcakes comply with New Jersey’s regulations for edibles, and they are only available for purchase by adults 21 and over. Proper identification is required upon delivery or pickup.",
      },
      {
        question: "When will cannabis-infused products be available?",
        answer:
          "Our cannabis-infused cupcakes will be available soon through our food truck at events like festivals and concerts. Stay tuned for updates on where you can find us!",
      },
      {
        question: "How strong are the cannabis-infused cupcakes?",
        answer:
          "Each cupcake contains a controlled dosage of THC to ensure a safe and enjoyable experience. Please refer to our product descriptions for detailed information on potency.",
      },
      {
        question: "Can I order cannabis-infused products online?",
        answer:
          "Due to current regulations, cannabis-infused products will only be available for in-person purchase through our food truck. Online sales are restricted.",
      },
    ],
  },
  {
    category: "Payment & Cancellation FAQs",
    faqs: [
      {
        question: "What forms of payment do you accept?",
        answer:
          "We accept all major credit cards, including Visa, Mastercard, and American Express, as well as digital payments like PayPal and Apple Pay.",
      },
      {
        question: "What is your refund policy?",
        answer:
          "Due to the custom nature of our products, we do not offer refunds. However, if there is an issue with your order, please contact us within 24 hours of delivery, and we’ll do our best to make it right.",
      },
      {
        question: "Can I cancel or modify my order?",
        answer:
          "You may cancel or modify your order up to 48 hours before your scheduled delivery. Please contact us as soon as possible if you need to make changes.",
      },
    ],
  },
  {
    category: "Health & Safety FAQs",
    faqs: [
      {
        question: "How are your products packaged for safety?",
        answer:
          "All of our products are securely packaged in eco-friendly, food-safe containers to ensure they arrive fresh and intact.",
      },
      {
        question: "Do you have allergen-friendly options?",
        answer:
          "We offer gluten-free, dairy-free, and nut-free options. However, please note that all products are made in a kitchen that handles gluten, nuts, and dairy, so cross-contamination may occur.",
      },
    ],
  },
  {
    category: "Contact FAQs",
    faqs: [
      {
        question: "How can I contact you?",
        answer:
          "You can reach us by filling out the contact form on our website or by emailing us directly at order@deydeybakesllc.com. For urgent inquiries, please call us at (202)520-3426.",
      },
      {
        question: "Do you have a physical storefront?",
        answer:
          "We do not have a physical storefront. All orders are placed online and delivered directly to your doorstep or event.",
      },
    ],
  },
];

export default faqData;
