// src/pages/ProductsPage.js

import React, { useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { cakes, cupcakes, pies, seasonal } from '../data/Products';
import ProductCard from '../components/Products/ProductCard';
import SeasonalBanner from '../components/Products/SeasonalBanner';
import '../styles/ProductsPage.css';
import ShippingProcess from '../components/Products/ShippingProcess';
import heart from '../assets/images/heart.png'; // Import the image you want to display
import QuoteForm from '../components/Products/QuoteForm'; // Ensure QuoteForm is imported

const ProductsPage = () => {
  const [activeCategory, setActiveCategory] = useState('All');
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedFlavors, setSelectedFlavors] = useState([]);
  const [selectedOccasion, setSelectedOccasion] = useState('');
  const [showBestSellers, setShowBestSellers] = useState(false);

  // State for QuoteForm
  const [isQuoteFormOpen, setIsQuoteFormOpen] = useState(false);
  const [quoteProduct, setQuoteProduct] = useState(null);

  const allProducts = [...cakes, ...cupcakes, ...pies, ...seasonal];

  // Get unique flavors from all products
  const allFlavors = Array.from(
    new Set(allProducts.flatMap((product) => product.flavors || []))
  ).sort();

  const occasions = ['Birthday', 'Wedding', 'Anniversary', 'Holiday', 'Other'];

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const handleFlavorChange = (e) => {
    const value = e.target.value;
    setSelectedFlavors((prev) =>
      prev.includes(value) ? prev.filter((flavor) => flavor !== value) : [...prev, value]
    );
  };

  const handleOccasionChange = (e) => {
    setSelectedOccasion(e.target.value);
  };

  const handleBestSellersChange = () => {
    setShowBestSellers((prev) => !prev);
  };

  const handleRequestQuote = (product, selectedSize, selectedFlavor, selectedFilling, selectedButtercream) => {
    setQuoteProduct({
      product,
      selectedSize,
      selectedFlavor,
      selectedFilling,
      selectedButtercream
    });
    setIsQuoteFormOpen(true);
  };

  const handleCloseQuoteForm = () => {
    setIsQuoteFormOpen(false);
    setQuoteProduct(null);
  };

  const filteredProducts = allProducts.filter((product) => {
    const matchesCategory =
      activeCategory === 'All' || product.category === activeCategory;
    const matchesSearch = product.name.toLowerCase().includes(searchQuery.toLowerCase());

    const matchesFlavors =
      selectedFlavors.length === 0 ||
      (product.flavors && selectedFlavors.some((flavor) => product.flavors.includes(flavor)));

    const matchesOccasion =
      selectedOccasion === '' ||
      (product.occasions && product.occasions.includes(selectedOccasion));

    const matchesBestSellers = !showBestSellers || product.bestSeller;

    return matchesCategory && matchesSearch && matchesFlavors && matchesOccasion && matchesBestSellers;
  });

  return (
    <div className="products-page">
      <Helmet>
        <title>Our Products | Dey Dey Bakes</title>
        <meta
          name="description"
          content="Explore our delicious range of cakes, cupcakes, pies, and seasonal treats. Order online from the best bakery in New York and New Jersey."
        />
      </Helmet>

      {/* Seasonal Banner */}
      <SeasonalBanner />

      {/* New "How to Order" Section */}
      <section className="how-to-order">
        <h2>How to Order</h2>
        <div className="order-container">
          <div className="order-text">
            <ol className="order-steps">
              <li>
                <strong>Request a Quote:</strong> Select the product you wish to order and click on "Request a Quote." Fill out the form with your details and any specific requirements.
              </li>
              <li>
                <strong>Add To Cart:</strong> If you choose to buy one of our cakes without a quote, the basic design of the cake or cupcakes will be applied along with the filling and buttercream of your choice.
              </li>
              <li>
                <strong>Receive Confirmation:</strong> After submitting your quote request, you'll receive an email confirming your request.
              </li>
              <li>
                <strong>Contact from Shawn:</strong> Shawn will reach out to you to discuss pricing, finalize details, and answer any questions you may have.
              </li>
              <li>
                <strong>Make a Deposit:</strong> A 10% deposit is required to secure your order. This deposit ensures your order is placed and will be prepared.
              </li>
              <li>
                <strong>Production and Delivery:</strong> It will take approximately one week to make and deliver your order. We'll keep you updated on the progress and notify you once it's ready.
              </li>
            </ol>
          </div>
          <div className="order-image">
            <img src={heart} alt="How to Order Process" loading="lazy" />
          </div>
        </div>
      </section>

      {/* Main Section containing both filters and products */}
      <div className="products-section">


        {/* Product Grid */}
        <div className="product-grid">
          {filteredProducts.length > 0 ? (
            filteredProducts.map((product) => (
              <ProductCard 
                key={product.id} 
                product={product} 
                onRequestQuote={handleRequestQuote} // Pass the callback
              />
            ))
          ) : (
            <p>No products found matching your criteria.</p>
          )}
        </div>
      </div>

      {/* Shipping Process */}
      <ShippingProcess />

      {/* Quote Form Modal */}
      {isQuoteFormOpen && quoteProduct && (
        <QuoteForm
          onClose={handleCloseQuoteForm}
          product={quoteProduct.product}
          selectedFlavor={quoteProduct.selectedFlavor}
          selectedFilling={quoteProduct.selectedFilling}
          selectedButtercream={quoteProduct.selectedButtercream}
          selectedSize={quoteProduct.selectedSize}
        />
      )}
    </div>
  );
};

export default ProductsPage;
