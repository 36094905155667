import React from 'react';
import '../../styles/CategoryCards.css';
import cupcakes from '../../assets/images/cupcakesimg.png';
import cakes from '../../assets/images/cakes.jpeg';
import pies from '../../assets/images/pies.png';

const categories = [
  { name: 'Cakes', image: cakes, link: '/cakes' },
  { name: 'Cupcakes', image: cupcakes, link: '/cupcakes' },
  { name: 'Pies', image: pies, link: '/pies' },
  { name: 'THC Edibles', image: pies, link: '/thc' },
];

const CategoryCards = () => {
  const handleMouseMove = (e) => {
    const card = e.currentTarget.querySelector('.card-content');
    const cardRect = card.getBoundingClientRect();
    const cardWidth = cardRect.width;
    const cardHeight = cardRect.height;
    const centerX = cardRect.left + cardWidth / 2;
    const centerY = cardRect.top + cardHeight / 2;
    const mouseX = e.clientX - centerX;
    const mouseY = e.clientY - centerY;

    // Reverse the rotation angles
    const rotateX = (-1 * 15 * mouseY) / (cardHeight / 2);
    const rotateY = (+1 * 15 * mouseX) / (cardWidth / 2);

    card.style.transform = `rotateX(${rotateX}deg) rotateY(${rotateY}deg) scale(1.05)`;
  };

  const handleMouseLeave = (e) => {
    const card = e.currentTarget.querySelector('.card-content');
    card.style.transform = 'rotateX(0deg) rotateY(0deg) scale(1)';
    card.style.transition = 'transform 0.3s ease';
  };

  const handleMouseEnter = (e) => {
    const card = e.currentTarget.querySelector('.card-content');
    card.style.transition = 'none';
  };

  return (
    <section className="category-section">
      <h2 className="section-title">Explore Our Categories</h2>
      <div className="category-cards">
        {categories.map((category, index) => (
          <a
            key={index}
            href={category.link}
            className="category-card"
            onMouseMove={handleMouseMove}
            onMouseLeave={handleMouseLeave}
            onMouseEnter={handleMouseEnter}
          >
            <div
              className="card-content"
              style={{
                backgroundImage: `url(${category.image})`,
              }}
            >
              <h3 className="card-title">{category.name}</h3>
            </div>
          </a>
        ))}
      </div>
    </section>
  );
};

export default CategoryCards;