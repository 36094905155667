import React from 'react';
import { useNavigate } from 'react-router-dom';

const CTAButtons = () => {
  const navigate = useNavigate();

  return (
    <div className="mt-6 flex-col">
      <button
        className="bg-redMain text-white py-3 px-8 rounded-lg font-semibold hover:bg-deepRed"
        onClick={() => navigate('/products')}
      >
        Order Now
      </button>
    </div>
  );
};

export default CTAButtons;