// src/components/MeetTheBaker/SEO.js
import React from 'react';
import { Helmet } from 'react-helmet-async';

const SEO = () => {
  const schemaData = {
    "@context": "https://schema.org",
    "@type": "Person",
    "name": "Shawn Dey",
    "jobTitle": "Founder of Dey-Dey Bakes",
    "url": "https://www.deydeybakes.com/meetthebaker",
    "image": "https://www.deydeybakes.com/assets/images/shawn-selfie.jpg",
    "description": "Shawn is the passionate founder of Dey-Dey Bakes, specializing in handcrafted cakes and custom desserts.",
    "sameAs": [
      "https://www.instagram.com/deydeybakes",
      "https://www.facebook.com/deydeybakes",
      "https://www.twitter.com/deydeybakes"
    ]
  };

  return (
    <Helmet>
      <script type="application/ld+json">
        {JSON.stringify(schemaData)}
      </script>
    </Helmet>
  );
};

export default SEO;