// src/components/THC/THCAboutSection.js
import React from 'react';
import '../../styles/THC/THCAboutSection.css';
import thcAboutImage from '../../assets/images/thc/variety-infused.jpeg'; // Ensure this image exists

const THCAboutSection = () => {
  return (
    <section className="thc-about-section" id="about">
      <div className="thc-about-content">
        <img src={thcAboutImage} alt="THC Infused Cakes" className="thc-about-image" loading="lazy" />
        <article className="thc-about-text">
          <h2>About Our THC-Infused Edible Cakes</h2>
          <p>
            At Dey-Dey Bakes, we blend the art of baking with the relaxation benefits of THC to create delectable edible cakes. Each cake is carefully crafted using high-quality ingredients and precisely measured THC doses to ensure a consistent and enjoyable experience.
          </p>
          <p>
            Our edible cakes offer a discreet and delicious way to consume THC, perfect for social gatherings, special occasions, or personal relaxation. Whether you're new to THC-infused edibles or a seasoned enthusiast, our selection caters to all preferences and desired effects.
          </p>
          <p>
            Safety and quality are our top priorities. We adhere to strict dosing guidelines and source our THC from reputable suppliers to guarantee that every bite is both delicious and responsible.
          </p>
        </article>
      </div>
    </section>
  );
};

export default THCAboutSection;